import moment from "moment";
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { io } from 'socket.io-client';
import ConnUrls from '../../../../MyConns/Connections/ConnectURL';
import utilsOrders from '../../../../MyConns/_Utils/utilsOrders';
import utilsRests from '../../../../MyConns/_Utils/utilsRests';
import OrderFunctions from '../../../../MyFunctions/OrderFunctions';
import myFunctions from '../../../../myFunctions';
import { BuisListContext } from '../../../MyContexts/BuisList_Context';
import { MobiOrNotContext } from '../../../MyContexts/MobiOrNotContext';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import Breadcrumb from './Breadcrumb';
import BuisTollBar from './BuisTollBar';
import BuisTollBarMobi from './BuisTollBarMobi';
import ListOrdersTable from './ListOrdersTable';
import ListOrdersBlocks from "./ListOrdersBlocks";
import InputSet from "../../../MySections/PopUps/InputSet";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { UserContext } from "../../../MyContexts/Users_Context";



const OrderLst = (props) => {

    const history = useHistory();

    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
    const [user] = useContext(UserContext);
    const [buisLst, setBuisLst] = useContext(BuisListContext);
    const [userBuis, setUserBuis] = useState();
    const [orderlisttable, setOrderlisttable] = useState([]);
    const [thePopUp, setThePopUp] = useState("");
    const [srchText1, setSrchText1] = useState("");
    const [srchText2, setSrchText2] = useState("");
    const [startDTS, setStartDTS] = useState();
    const [endDTS, setEndDTS] = useState();
    const [totOfAll, setTotOfAll] = useState(0);
    const [totOfCancels, setTotOfCancels] = useState(0);
    const [showBetweenDts, setShowBetweenDts] = useState(false);
    const [lstCounter, setLstCounter] = useState(null);
    const [selectedBtn, setSelectedBtn] = useState(myFunctions.setGetCurrnetTab("Get", "ordLstBtn") || 6);
    const [listSordted, setListSordted] = useState({ sort: false, sortBy: "Asc" });
    const [intervalID, setIntervalID] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newRowToPush, setNewRowToPush] = useState("");
    const [newListToUp, setNewListToUp] = useState("");
    const [soundBlingInterval, setSoundBlingInterval] = useState(5000);
    const [soundBlingInterval1000, setSoundBlingInterval1000] = useState(10000);



    const [blingOn, setBlingOn] = useState(true);

    const [intervalBling, setIntervalBling] = useState(null);

    useEffect(() => {
        myFunctions.connCheck();
    })


    useEffect(() => {

        getBuisList();
        refreshTheList(selectedBtn);

    }, [])



    useEffect(() => {

        const socket = io.connect(ConnUrls.socketClient, {
            auth: {
                token: localStorage.getItem("token")
            },
            query: {
                userType: "back"
            }
        }
        );

        socket.on("orderUpdated", (data) => {
            setTimeout(() => {
                const { listUpdates, objUpdated, newVal } = data;

                if (listUpdates) {
                    setOrderlisttable((prevTableData) => {
                        const updatedOrderListtable = prevTableData.map((row) => {
                            // Check each element in listUpdates
                            for (const update of listUpdates) {
                                if (row.ord.orderNUM === update) {
                                    // Update the row using dynamic property access
                                    let tempBk = { ...row.ord }
                                    tempBk[objUpdated] = newVal;
                                    return { ...row, ord: tempBk };
                                }
                            }
                            // Return the original row if no match found
                            return row;
                        });

                        setBlingOn(true)
                        myFunctions.setGetCurrnetTab("Set", "soundOn", true)
                        myFunctions.hearUpdatedBling();

                        getTimes(updatedOrderListtable)

                        return updatedOrderListtable;
                    });


                }

            }, 500);
        })

        socket.on("newOrder", (data) => {
            setTimeout(() => {
                const { theNOrder } = data;

                if (theNOrder) {
                    setOrderlisttable((prevTableData) => {
                        if (!prevTableData.some(itm =>
                            itm.ord.orderNUM === theNOrder.ord.orderNUM)) {
                            prevTableData.push(theNOrder)
                        }
                        else {
                            let itmUp = prevTableData.findIndex(theord => theord.ord.orderNUM === theNOrder.ord.orderNUM);
                            if (itmUp !== -1) {
                                prevTableData[itmUp] = theNOrder;

                            }
                        }

                        setLstCounter(prevTableData.length);

                        getTheTotal(prevTableData);
                        setSrchText1("");
                        setSrchText2("");
                        setBlingOn(true)
                        myFunctions.setGetCurrnetTab("Set", "soundOn", true)

                        if (theNOrder.ord.paymentStatus === "ממתין לחיוב") {
                            if (user.permiss === "admin") {
                                myFunctions.hearNewNoPayBling();
                            }
                        }
                        else {
                            myFunctions.hearBling();
                        }


                        getTimes(prevTableData)

                        return prevTableData;
                    });


                }

            }, 3000);
        })

        socket.on("sendiUpdated", (data) => {
            const { orderNumber, newSendiStatus } = data;

            if (orderNumber) {


                setOrderlisttable((prevTableData) => {

                    let itmUp = prevTableData.findIndex(theord => theord.ord.orderNUM === orderNumber);
                    if (itmUp !== -1) {
                        let tempBk = { ...prevTableData[itmUp] }
                        tempBk.ord["sendiStatus"] = newSendiStatus;
                        prevTableData[itmUp] = tempBk;
                    }


                    setBlingOn(true)
                    myFunctions.setGetCurrnetTab("Set", "soundOn", true)
                    myFunctions.hearSendiUpBling();

                    // getTimes(prevTableData)

                    return prevTableData;
                });


            }


            // const row = document.getElementById(`${orderNumber}`);
            // if (row) {
            //     // Find the specific td with the class name "sendiStatus" in the row
            //     const sendiStatusCell = row.querySelector('.sendiStatus');
            //     const dvStat = sendiStatusCell.querySelector('.dvStat');

            //     if (sendiStatusCell) {
            //         // Update the inner text of the td with the newSendiStatus
            //         dvStat.innerText = newSendiStatus;
            //         dvStat.title = newSendiStatus;
            //     }
            // }

        })


    }, [ConnUrls.socket])



    const getBuisList = async () => {

        let busiLst = await utilsRests.getRestsListSmall();
        setBuisLst(myFunctions.sortAsc(busiLst, "buissName"))
    }


    const startHandler = (OrdrsList) => {

        if (intervalID != null) {
            clearInterval(intervalID)
        }
        // let theList = listSordted
        let theList = (!listSordted.sort && OrdrsList) ?
            (myFunctions.sortByItemDsc(OrdrsList, "dateCreated", "ord")) : OrdrsList;

        setIntervalID(setInterval(() => {

            theList.map((item, i) => {

                let theTimerId = item.ord._id + "_Cnt";
                var theELEMENT = window.document.getElementById(theTimerId);
                if (theELEMENT) {
                    if (item.ord.orderStatus != "סגור" && item.ord.orderStatus != "מושהה" && item.ord.orderStatus != "מבוטל") {
                        let theItm = MyDateTime.getTimeLeftTilThen(item.ord.dateCreated, item.ord.OrderTimes);
                        theELEMENT.innerText = theItm.theTm
                        theELEMENT.style.backgroundColor = theItm.theCol
                        theELEMENT.style.color = theItm.theForCol
                        theELEMENT.style.fontWeight = "bold"
                        theELEMENT.style.paddingRight = "5px"

                        let theSendiId = item.ord._id + "_SendiTm";
                        var theSendiElement = window.document.getElementById(theSendiId);
                        if (theSendiElement) {
                            let theSendiItm = myFunctions.getDeliveryTimes(item.ord.deliverysDetails);
                            theSendiElement.innerText = theSendiItm !== 0 && theSendiItm !== "" ? `(${theSendiItm})` : "";
                        }
                    }
                    else {
                        theELEMENT.innerText = ""
                    }


                }

            });

        }, 1000))


        checkForSound(OrdrsList);

    }


    const checkForSound = (OrdrsList) => {

        if (intervalBling != null) {
            clearInterval(intervalBling)
        }
        setIntervalBling(setInterval(() => {
            let onOff = window.document.getElementById("soundOnOff");
            if (onOff != null) {
                if (onOff.value === "true") {
                    let blingIT = OrdrsList.filter(itm => itm.ord.orderStatus === 'ממתין לתגובה' &&
                        itm.ord.paymentStatus !== "ממתין לחיוב").length > 0

                    if (blingIT) {
                        myFunctions.hearBling();
                    }
                }
            }
            else {
                clearInterval(intervalBling)
            }
        }, soundBlingInterval))
    }


    const stopHandler = () => {
        // clearInterval(intervalID)

        var highestTimeoutId = setInterval(";");

        for (var i = 0; i < highestTimeoutId; i++) {
            clearInterval(i);
        }
    }

    const getTimes = (OrdrsList) => {

        stopHandler()
        startHandler(OrdrsList)
    }


    // //מוסיף הזמנה חדשה דינאמית
    // const addRowToList = (newOrdr) => {
    //     let lst = [...orderlisttable]
    //     lst.push(newOrdr)

    //     setLstCounter(lst.length);
    //     setOrderlisttable(lst);
    //     getTheTotal(lst);
    //     setSrchText("");
    //     getTimes(lst);

    //     setNewRowToPush("");
    // }

    // //מעדכן הזמנה דינאמית
    // const updateFullOrderRow = (newOrdr) => {
    //     let lst = [...orderlisttable]
    //     let indx = lst.findIndex(ord => ord.ord.orderNUM === newOrdr.ord.orderNUM);
    //     lst[indx] = newOrdr;

    //     setLstCounter(lst.length);
    //     setOrderlisttable(lst);
    //     getTheTotal(lst);
    //     setSrchText("");
    //     getTimes(lst);

    //     setNewRowToPush("");
    // }


    // //מעדכן סטטוס לרשימת הזמנות
    // const updateNewListToUp = (NListToUp) => {
    //     let lst = [...orderlisttable];
    //     (NListToUp.listUpdates &&
    //         NListToUp.listUpdates.map(element => {
    //             let indx = lst.findIndex(ord => ord.ord.orderNUM === element);
    //             lst[indx].ord.orderStatus = NListToUp.newVal;
    //         }))

    //     setLstCounter(lst.length);
    //     setOrderlisttable(lst);
    //     getTheTotal(lst);
    //     setSrchText("");
    //     getTimes(lst);

    //     setNewListToUp("")
    // }

    const setAllLists = (btnInt, OrdrsList) => {

        setLstCounter(OrdrsList.length)
        setOrderlisttable(OrdrsList);
        getTheTotal(OrdrsList)
        setSrchText1("");
        setSrchText2("");
        setSelectedBtn(btnInt)
        myFunctions.setGetCurrnetTab("Set", "ordLstBtn", btnInt)
        getTimes(OrdrsList)

        setShowBetweenDts(false)
    }

    const refreshTheList = (btnInt) => {
        const buttonFunctions = {
            "6": showTodaysOpen,
            "7": showTodaysAll,
            "8": showYesterdayAll,
            "9": showMounth,
            "10": () => {
                setShowBetweenDts(!showBetweenDts);
                setSelectedBtn(10);
            },
            "11": () => showByDates(10),
            "12": showAllOpen,
        };

        const selectedFunction = buttonFunctions[btnInt.toString()] || showTodaysOpen;
        selectedFunction(btnInt);


    };

    //פתוחות היום
    const showTodaysOpen = async (btnInt) => {
        setLoading(true)

        var today = MyDateTime.getSpecificDate(new Date(), 0);
        let fixToday = parseInt(moment(today).hours()) > 5 ? today : MyDateTime.getSpecificDate(today, -1);

        var yesterday = parseInt(moment(today).hours()) > 5 ?
            MyDateTime.setTimeByStr(today, { hh: "05", mm: "00", ss: "00" }) :
            MyDateTime.setTimeByStr(MyDateTime.getSpecificDate(today, -1), { hh: "05", mm: "00", ss: "00" });

        setStartDTS(fixToday);
        setEndDTS(yesterday);

        let OrdrsList = await utilsOrders.getOpenOrders(yesterday, today);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);
        setLoading(false)

    }

    //כל הפתוחות
    const showAllOpen = async (btnInt) => {
        setLoading(true)

        var today = MyDateTime.getSpecificDate(new Date(), 0);
        var yesterday = MyDateTime.getSpecificDate(today, -730);

        setStartDTS(today);
        setEndDTS(yesterday);

        let OrdrsList = await utilsOrders.getOpenOrders(yesterday, today, userBuis);

        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);

        setLoading(false)

    }


    //הכל מהיום
    const showTodaysAll = async (btnInt) => {
        setLoading(true)


        var today = MyDateTime.getSpecificDate(new Date(), 0);
        let fixToday = parseInt(moment(today).hours()) > 5 ? today : MyDateTime.getSpecificDate(today, -1);

        var yesterday = parseInt(moment(today).hours()) > 5 ?
            MyDateTime.setTimeByStr(today, { hh: "05", mm: "00", ss: "00" }) :
            MyDateTime.setTimeByStr(MyDateTime.getSpecificDate(today, -1), { hh: "05", mm: "00", ss: "00" });

        setStartDTS(fixToday);
        setEndDTS(yesterday);


        let OrdrsList = await utilsOrders.getAllOrders(yesterday, today);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);

        setLoading(false)

    }



    //הכל מאתמול
    const showYesterdayAll = async (btnInt) => {
        setLoading(true)

        var day1 = MyDateTime.getSpecificDate(new Date(), -1);
        day1 = MyDateTime.setTimeByStr(day1, { hh: "05", mm: "00", ss: "00" })

        var day2 = MyDateTime.getSpecificDate(day1, 1);
        var day2 = MyDateTime.setTimeByStr(day2, { hh: "05", mm: "00", ss: "00" })

        setStartDTS(day1);
        setEndDTS(day2);

        let OrdrsList = await utilsOrders.getAllOrders(day1, day2);

        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);

        setLoading(false)

    }

    //הכל חודש
    const showMounth = async (btnInt) => {
        setLoading(true)

        var day1 = MyDateTime.getSpecificDate(new Date(), 0);
        var day2 = MyDateTime.getFirstDayinMounth(day1);

        setStartDTS(day1);
        setEndDTS(day2);

        let OrdrsList = await utilsOrders.getAllOrders(day2, day1);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);
        setLoading(false)

    }

    //לפי תאריכים
    const showByDates = async (btnInt) => {
        setLoading(true)

        var day1 = MyDateTime.getSpecificDate(startDTS);
        var day2 = MyDateTime.getSpecificDate(endDTS);

        setStartDTS(day1);
        setEndDTS(day2);

        let OrdrsList = await utilsOrders.getAllOrders(day1, day2);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        // let OrdrsList = await utilsOrders.getAllOrders(endDTS, startDTS);
        setAllLists(btnInt, OrdrsList);
        setLoading(false)

    }


    const showOpenOrders = async (btnInt) => {

        let OrdrsList = await utilsOrders.getOpenOrders(endDTS, startDTS);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);

    }


    const showClosedOrders = async (btnInt) => {

        let OrdrsList = await utilsOrders.getClosedOrders(endDTS, startDTS);
        OrdrsList = myFunctions.getListByBuisId(OrdrsList, userBuis);

        setAllLists(btnInt, OrdrsList);

    }

    const setNewStatus = async (orderId, statBack) => {
        if (statBack !== "מבוטל") {
            let updteme = await utilsOrders.updateStatus(orderId, statBack);
        }
        else {
            cancelOrderapproval(orderId);
        }

    }

    const cancelOrderapproval = (orderId) => {

        setThePopUp(<InputSet ContentText={`מהי סיבת הביטול?`}
            okClick={(inputTxt) => okCancel(inputTxt, orderId)}
            cancelClick={() => { setThePopUp() }}
            errorMsg={"חובה להזין סיבה לביטול"} />)

        const okCancel = async (cancelReason, orderId) => {
            let updteme = await utilsOrders.updateStatusToCancel(orderId,
                "מבוטל", cancelReason);
        }

    }

    const setNewStatusToList = async (theSelects, newStatus) => {

        if (newStatus !== "מבוטל") {
            let updteme = await utilsOrders.updateStatusToList(theSelects, newStatus);
        }
        else {
            cancelListStatusapproval(theSelects);
        }
    }

    const cancelListStatusapproval = (theSelects) => {

        setThePopUp(<InputSet ContentText={`מהי סיבת הביטול?`}
            okClick={(inputTxt) => okCancel(inputTxt, theSelects)}
            cancelClick={() => { setThePopUp() }}
            errorMsg={"חובה להזין סיבה לביטול"} />)

        const okCancel = async (cancelReason, theSelects) => {
            let updteme = await utilsOrders.updateStatusToListToCancel(theSelects,
                "מבוטל", cancelReason);
        }

    }



    const rowCellClick = (ordId) => {
        stopHandler();
        history.push('/Order/' + ordId);
    }

    const checkIfEnter = (e, byWhat) => {
        if (e.key === 'Enter') {
            searchOrder(byWhat);
        }
    }

    const searchOrder = async (byWhat) => {
        setLoading(true)

        let txt = (byWhat === "byAll") ? srchText2 : (byWhat === "orderNUM") ? srchText1 : "";
        let OrdrsBk = await utilsOrders.getOrderBySearch(txt, startDTS, endDTS, byWhat);
        if (OrdrsBk != null && OrdrsBk.errMsg !== 'error') {
            setOrderlisttable(OrdrsBk);
            getTheTotal(OrdrsBk)
            getTimes(OrdrsBk)
        }
        setLoading(false)

    }


    const getTheTotal = (OrdrsBk) => {
        let totAll = 0;
        let Cancels = 0;
        OrdrsBk.forEach(element => {
            if (element.ord.paymentStatus !== "ממתין לחיוב") {
                totAll += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                if (element.ord.orderStatus === "מבוטל") {
                    Cancels += parseFloat(OrderFunctions.getOrderTotalPrice(element.ord));
                }

            }
        });

        setTotOfAll(totAll);
        setTotOfCancels(Cancels);

    }


    const orderBythis = (byWhat, ordUserOrBuiss) => {
        if (listSordted.sortBy === "Asc") {
            setOrderlisttable(myFunctions.sortByItemAsc(orderlisttable, byWhat, ordUserOrBuiss))
            setAllLists(selectedBtn, myFunctions.sortByItemAsc(orderlisttable, byWhat, ordUserOrBuiss));
            setListSordted({ sort: true, sortBy: "Dsc" })
        }
        else {
            setOrderlisttable(myFunctions.sortByItemDsc(orderlisttable, byWhat, ordUserOrBuiss))
            setAllLists(selectedBtn, myFunctions.sortByItemDsc(orderlisttable, byWhat, ordUserOrBuiss));
            setListSordted({ sort: true, sortBy: "Asc" })
        }

    }


    // let theList = listSordted
    let theList = (!listSordted.sort && orderlisttable) ?
        (myFunctions.sortByItemDsc(orderlisttable, "dateCreated", "ord")) : orderlisttable;

    return (
        <>

            <div className="ms-content-wrapper">
                <div className="ordrspanel">
                    <Breadcrumb />
                    {/* Active Orders Graph */}
                    <div className="ordrspanel">
                        <div className="ms-panel">
                            {!mobiOrNot ?
                                <BuisTollBar
                                    selectedBtn={selectedBtn}
                                    refreshTheList={(selectedBtn) => refreshTheList(selectedBtn)}
                                    srchText1={srchText1}
                                    srchText2={srchText2}
                                    setSrchText1={(txt) => setSrchText1(txt)}
                                    setSrchText2={(txt) => setSrchText2(txt)}
                                    searchOrder={(txt) => searchOrder(txt)}
                                    showBetweenDts={showBetweenDts}
                                    setShowBetweenDts={(btdats) => setShowBetweenDts(btdats)}
                                    showAllOpen={(indx) => showAllOpen(indx)}
                                    startDTS={startDTS}
                                    setStartDTS={(dt) => setStartDTS(dt)}
                                    endDTS={endDTS}
                                    setEndDTS={(dt) => setEndDTS(dt)}
                                    checkIfEnter={(e, byWhat) => checkIfEnter(e, byWhat)}
                                    setUserBuis={(newVal) => { setUserBuis(newVal) }}
                                    soundOn={blingOn}
                                    setSoundOn={(tr) => setBlingOn(tr)}
                                />
                                :
                                <BuisTollBarMobi
                                    selectedBtn={selectedBtn}
                                    refreshTheList={(selectedBtn) => refreshTheList(selectedBtn)}
                                    srchText1={srchText1}
                                    srchText2={srchText2}
                                    setSrchText1={(txt) => setSrchText1(txt)}
                                    setSrchText2={(txt) => setSrchText2(txt)}
                                    searchOrder={(txt) => searchOrder(txt)}
                                    showBetweenDts={showBetweenDts}
                                    setShowBetweenDts={(btdats) => setShowBetweenDts(btdats)}
                                    showAllOpen={(indx) => showAllOpen(indx)}
                                    startDTS={startDTS}
                                    setStartDTS={(dt) => setStartDTS(dt)}
                                    endDTS={endDTS}
                                    setEndDTS={(dt) => setEndDTS(dt)}
                                    checkIfEnter={(e, byWhat) => checkIfEnter(e, byWhat)}
                                    setUserBuis={(newVal) => { setUserBuis(newVal) }}
                                    soundOn={blingOn}
                                    setSoundOn={(tr) => setBlingOn(tr)}
                                />
                            }


                            {loading ?
                                <>
                                    <MySpinner_Wait />
                                    <br />
                                    <br />
                                </>
                                :
                                <div className="fullRow">
                                    <div className="col-md-12">

                                        {!mobiOrNot ?
                                            <>
                                                <div className={"totalsLine"}>
                                                    <div className={"fullRow"}>
                                                        <div className={"onLeft spaceMargeRightbig"}>סה"כ: {parseInt(totOfAll)} ₪</div>
                                                        <div className={"onLeft "}>הזמנות: {orderlisttable.filter(itm => itm.ord.paymentStatus !== "ממתין לחיוב").length}</div>
                                                    </div>
                                                </div>
                                                {totOfCancels !== 0 &&
                                                    <div className={"totalsLine"}>
                                                        <div className={"onLeft spaceMargeRightbig"}>סה"כ: {parseInt(totOfCancels)} ₪</div>
                                                        <div className={"onLeft "}>ביטולים: {orderlisttable.filter(itm => itm.ord.orderStatus === "מבוטל" && itm.ord.paymentStatus !== "ממתין לחיוב").length}</div>
                                                    </div>
                                                }
                                                <ListOrdersTable
                                                    theList={
                                                        (!listSordted.sort && orderlisttable) ?
                                                            (myFunctions.sortByItemDsc(orderlisttable, "dateCreated", "ord")) : orderlisttable
                                                    }
                                                    orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                                                    rowCellClick={(ordId) => rowCellClick(ordId)}
                                                    setNewStatus={(orderId, statBack) => setNewStatus(orderId, statBack)}
                                                    setNewStatusToList={(theSelectsList, newStatus) => setNewStatusToList(theSelectsList, newStatus)}


                                                />
                                            </>
                                            :
                                            <>
                                                <div className={"totalsLine"}>
                                                    <div className={"onRight "}>הזמנות: {orderlisttable.filter(itm => itm.ord.paymentStatus !== "ממתין לחיוב").length}</div>
                                                    <div className={"onRight spaceMargeRightbig"}>סה"כ: {parseInt(totOfAll)} ₪</div>
                                                </div>

                                                {totOfCancels !== 0 &&
                                                    <div className={"totalsLine"}>
                                                        <div className={"onRight "}>ביטולים: {orderlisttable.filter(itm => itm.ord.orderStatus === "מבוטל" && itm.ord.paymentStatus !== "ממתין לחיוב").length}</div>
                                                        <div className={"onRight spaceMargeRightbig"}>סה"כ: {parseInt(totOfCancels)} ₪</div>
                                                    </div>
                                                }
                                                <ListOrdersBlocks
                                                    theList={
                                                        (!listSordted.sort && orderlisttable) ?
                                                            (myFunctions.sortByItemDsc(orderlisttable, "dateCreated", "ord")) : orderlisttable
                                                    }
                                                    orderBythis={(byWhat, ordUserOrBuiss) => orderBythis(byWhat, ordUserOrBuiss)}
                                                    rowCellClick={(ordId) => rowCellClick(ordId)}
                                                    setNewStatus={(orderId, statBack) => setNewStatus(orderId, statBack)}
                                                    setNewStatusToList={(theSelectsList, newStatus) => setNewStatusToList(theSelectsList, newStatus)}

                                                />
                                            </>

                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <input type={"hidden"} id="soundOnOff" value={blingOn} />
                </div>
                {thePopUp}

            </div >

        </>
    );


}

export default OrderLst;

