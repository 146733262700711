import React, { Component, useContext, useEffect, useState } from 'react';

import Breadcrumb from './Breadcrumb'
import ReactDOM from 'react-dom'
import { Link, useParams, useHistory } from 'react-router-dom'
import utilsOrders from '../../../../../MyConns/_Utils/utilsOrders';
import MyDateTime from '../../../../MySections/TimeSections/MyDateTime';
import SucessToast from '../../../../MySections/Toasts/SucessToast';
import OrderFunctions from '../../../../../MyFunctions/OrderFunctions';
import PrintingStyle from '../../../../../MyFunctions/PrintingStyle';

import OrderStatuselect from '../../OrdersList-list/OrderStatuselect';
import { Button } from '@mui/material';
import PrintTemplate from 'react-print';
import { display } from '@mui/system';
import InvoicePrint from './InvoicePrint';
import TextBoxSet from '../../../../MySections/PopUps/TextBoxSet';
import parse from 'html-react-parser';
import InvoiceTable from './InvoiceTable';
import InvoiceMobiTable from './InvoiceMobiTable';


import { ScreenWidthContext } from '../../../../MyContexts/ScreenWidth_Context';
import UpdateOrderDetails from '../../../../MySections/PopUps/UpdateOrderDetails';
import MySpinner_Wait from '../../../../MySections/DTechComps/MySpinner_Wait';
import Translator from '../../../../../MyFunctions/Translator';
import InputSet from '../../../../MySections/PopUps/InputSet';
import DeliveryDetails from '../../DeliveryDetails/DeliveryDetails';
import PopUpTheComp from '../../../../MySections/DTechComps/PopUpTheComp';


const Content = (props) => {

    const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);
    const [loading, setLoading] = useState(true);
    const [theOrder, setTheOrder] = useState([]);
    const [times, setTimes] = useState([]);
    const [printMe, setPrintMe] = useState([false]);
    const [thePopUp, setThePopUp] = useState("");
    const [arabic, setArabic] = useState("عربيه");

    let idParam = useParams();

    const history = useHistory();


    useEffect(() => {

        loadTheOrder();
    }, [])


    const loadTheOrder = async () => {
        if (props.ordId) {
            let Orderloaded = await utilsOrders.getOrder(props.ordId);
            setTheOrder(Orderloaded);
            getTimes(Orderloaded)
            setLoading(false);
        }
        else {
            history.push("/Orders");
        }

    }

    const getTimes = (ord) => {

        setInterval(() => {
            let theItm = MyDateTime.getTimeLeftTilThen(ord.ord.dateCreated, ord.ord.OrderTimes);
            setTimes(theItm)

        }, 1000);
    }



    const updateTheOrder = async () => {

        let updteme = await utilsOrders.updateOrder(theOrder.ord._id, theOrder);
        loadTheOrder();
        SucessToast({ position: "toast-bottom-right", text: "עודכן בהצלחה!" });
    }

    const getAddress =
        (theOrder.ord?.OrderType !== "Take") ?
            <>
                <br />
                <div className={"bold"}>יעד:</div>
                <div className={"bold big "}>
                    &nbsp;
                    {theOrder.ord?.deliveryAddress.street}
                    &nbsp;

                    {(!theOrder.ord?.deliveryAddress.street.endsWith(theOrder.ord?.deliveryAddress.num)) ?
                        theOrder.ord?.deliveryAddress.num
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.appartment) ?
                        `דירה: ${theOrder.ord?.deliveryAddress.appartment}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.ent) ?
                        `כניסה: ${theOrder.ord?.deliveryAddress.ent}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {(theOrder.ord?.deliveryAddress.flor) ?
                        `קומה: ${theOrder.ord?.deliveryAddress.flor}`
                        : ""}
                    &nbsp;
                    &nbsp;
                    {theOrder.ord?.deliveryAddress.city || "אשדוד"}


                </div>
                <div className={"bold regular "}>
                    {(theOrder.ord?.deliveryAddress.remarks) ?
                        `הערות: ${theOrder.ord?.deliveryAddress.remarks}`
                        : ""}
                    &nbsp;
                    &nbsp;
                </div>
            </> : "";



    const clientDetails =
        <>
            <div className={"spac spacePadBig specialRow"}>
                <div className={"bold"}>שם הלקוח: {theOrder.usr?.firstName}  {theOrder.usr?.lastName}</div>
                <div className={"bold"}>טלפון: {theOrder.usr?.phone}</div>
                {theOrder.usr?.email != "" ? <div className={"bold"}>אימייל: {theOrder.usr?.email}</div> : ""}
            </div>

        </>;


    const popUpdateOrder = () => {
        setThePopUp(
            <UpdateOrderDetails Title={"עריכת פרטים"}
                theOrder={theOrder} inLabel={""}
                okClick={(theOrderBk) => popMe(theOrderBk)}
                cancelClick={() => setThePopUp()}
                errorMsg={""}
            />)


    }
    const popMe = (theOrderBk) => {
        setTheOrder(theOrderBk)
        // console.log(theOrderBk)
        updateTheOrder();
    }

    const printIt = () => {
        setPrintMe(true)

        var content = document.getElementById("printingCloser");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML + PrintingStyle.mediaprint());
        pri.document.close();
        pri.focus();
        pri.print();


        // window.print()
        setPrintMe(false)
    }



    const ShowInArab = () => {
        if (arabic === "عربيه") {
            setArabic("עברית");
            setTheOrder(Translator.Arabic(theOrder));

        } else {
            setArabic("عربيه");
            setTheOrder(Translator.BackToHeb(theOrder));
        }
    }


    const setNewStatus = async (orderId, statBack) => {

        if (statBack !== "מבוטל") {
            let updteme = await utilsOrders.updateStatus(orderId, statBack);
            loadTheOrder();
        }
        else {
            cancelOrderapproval(orderId);
        }

    }
    const cancelOrderapproval = (orderId) => {

        setThePopUp(<InputSet ContentText={`מהי סיבת הביטול?`}
            okClick={(inputTxt) => okCancel(inputTxt, orderId)}
            cancelClick={() => { setThePopUp() }}
            errorMsg={"חובה להזין סיבה לביטול"} />)

        const okCancel = async (cancelReason, orderId) => {
            let updteme = await utilsOrders.updateStatusToCancel(orderId,
                "מבוטל", cancelReason);
            loadTheOrder();
        }

    }

    const setRemarks = () => {
        setThePopUp(
            <TextBoxSet Title={"הערות הזמנה"}
                textFirst={theOrder.ord.orderRemarks} inLabel={""}
                okClick={(textBack) => setRemarksApprov(textBack)}
                cancelClick={() => setThePopUp()}
                errorMsg={""}
            />)
    }


    const setRemarksApprov = (textBack) => {
        // alert(textBack)


        theOrder.ord.orderRemarks = textBack;

        setTheOrder(theOrder);
        getTimes(theOrder)

    }

    const addOrderItem = () => {
        setThePopUp(
            // <RestMnu buisMenu={restPage.buisMenu} nameBuis={restPage.buissName} restPage={restPage}
            //     doDeliver={temp_doDeliver}
            //     doTakeAway={temp_doTakeAway}
            //     setShowHideMoreDet={() => setShowHideMoreDet("showOnLeft")}
            //     dispOnlyDelivery={dispOnlyDelivery} BuisId={restPage._id}
            //     setDispOnlyDelivery={() => setDispOnlyDelivery(!dispOnlyDelivery)} />

        )
    }

    const PopDeliveryDetails = (orderNUM, buisName, AddrsTo) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"OrderDispPop"}
                popDiagStyle={"OrderDispValPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["פרטי השליחות"]}
                theInsideComp={
                    <DeliveryDetails cssStyl={"OrderDisplayPop"}
                        cancelClick={() => setThePopUp()}
                        okClick={() => { setThePopUp() }}
                        Title={[""]}
                        ordNum={orderNUM}
                        buisName={buisName}
                        AddrsTo={AddrsTo}
                    />}
            />

        )



    }


    return (
        loading ? <MySpinner_Wait /> :
            <>
                <div className="no_print">
                    <div className="ms-content-wrapper">
                        <div className={`row no_print`}>
                            <div className="col-md-12">
                                <div><Breadcrumb /></div>
                                <div className="ms-panel theOrderwrap">
                                    <div className={"onRight spaceMargeTopbig spaceMargeRightbig"}>
                                        <Button className={"btnMediumMnu"} variant="contained" onClick={() => history.push('/Orders')}>
                                            לכל ההזמנות
                                        </Button>
                                    </div>

                                    <div className="onLeft spaceMargeTopbig spaceMargeRightbig">
                                        <Button className={"btnMediumMnu"} variant="contained" onClick={() => printIt()}>
                                            הדפס הזמנה
                                        </Button>

                                    </div>
                                    {theOrder.rst.arabic ? <>
                                        <div className="onLeft spaceMargeTopbig spaceMargeRightbig">
                                            <Button className={"btnMediumMnu"} variant="contained" onClick={() => ShowInArab()}>
                                                {arabic}
                                            </Button>

                                        </div>
                                    </> : ""}
                                    <div className="ms-panel-header header-mini centerText fullRow">
                                        <h3 className={"spaceMargeTopbig"}>{`${theOrder.rst?.buissName}`}</h3>
                                    </div>

                                    <div className="col-md-12 overflowHid">

                                        <div className="ms-panel-header header-mini col-md-6 onRight">
                                            <div className="d-flex justify-content-between onRight fullOnPrint spaceMargeTopbig">
                                                <h6>{`הזמנה #${theOrder.ord?.orderNUM}`}</h6>
                                            </div>
                                            <div className={"onLeft spaceMargeTopbig spaceMargeRightbig"}>
                                                <Button className={"btnMediumMnu"} variant="contained" onClick={() => popUpdateOrder()}>
                                                    ערוך פרטים
                                                </Button>
                                            </div>
                                            <br />
                                            <h4 className="DelORGet d-flex justify-content-between bold fullRow">
                                                {(theOrder.ord?.OrderType === "Deliver") ? "משלוח" : "איסוף"}
                                            </h4>
                                            <h6 className="DelORGet d-flex justify-content-between bold fullRow">
                                                אופן תשלום: {OrderFunctions.payingTYpe(theOrder)}
                                            </h6>

                                            <h6 className="DelORGet d-flex justify-content-between bold fullRow">
                                                סטטוס תשלום: {theOrder.ord?.paymentStatus}
                                            </h6>

                                            {/* Invoice To */}
                                            <div className="row align-items-center">
                                                <div className={"col-md-12 onlyOnPrint"}>
                                                    <div className="invoice-address">
                                                        {(theOrder.ord?.OrderType === "Deliver") ?
                                                            getAddress : ""}
                                                        {clientDetails}
                                                    </div>
                                                </div>

                                                <div className={"col-md-12"}>
                                                    <div className="invoice-address">
                                                        {(theOrder.ord?.OrderType === "Deliver") ?
                                                            getAddress : ""}

                                                        {clientDetails}
                                                        <div className={"spaceMargeTopbig"}>
                                                            <Button className={"btnMediumMnu"} variant="contained" onClick={() => setRemarks()}>הערות</Button>
                                                        </div>

                                                        {(theOrder.ord?.orderRemarks != null) ?
                                                            <div className={"pointing"} onClick={() => setRemarks()}>
                                                                <div className="veryBigFontImportant bold">{(theOrder.ord?.orderRemarks != null) ? parse(`${theOrder.ord.orderRemarks}`) : ""} </div>
                                                            </div>
                                                            : ""}
                                                    </div>


                                                </div>

                                            </div>
                                        </div>

                                        <div className="ms-panel-header header-mini col-md-6 onRight dirLeft">

                                            <div className="d-flex justify-content-between onLeft fullRow fullOnPrint">
                                                <div className={"bold centerText"}>יצירת ההזמנה: {MyDateTime.getDateAndTime(theOrder.ord?.dateCreated)}</div>
                                            </div>

                                            {(theOrder.ord?.lastUpdate) ? <>
                                                <div className="d-flex justify-content-between onLeft fullRow fullOnPrint">
                                                    <div className={"bold centerText "}>עדכון אחרון: {MyDateTime.getDateAndTime(theOrder.ord?.lastUpdate)}</div>
                                                </div>
                                                <div className="d-flex justify-content-between onLeft fullRow fullOnPrint">
                                                    <div className={"bold centerText "}>עודכן ע"י: {theOrder.ord?.lastUpdaterBy || ""}</div>
                                                </div>
                                            </>
                                                : ""}

                                            {(theOrder.ord?.dateClosed) ? <>
                                                <br />
                                                <div className="d-flex justify-content-between onLeft fullOnPrint">
                                                    <div className={"bold centerText"}>סגירת ההזמנה: {MyDateTime.getDateAndTime(theOrder.ord?.dateClosed)}</div>
                                                </div>
                                                <br />

                                                <div className="d-flex justify-content-between onLeft fullOnPrint">
                                                    <div className={"bold centerText"}>נסגר על ידי: {theOrder.ord?.closedBY}</div>
                                                </div>
                                                <br />
                                            </>
                                                : ""}
                                            <div className={"statBlock"}>

                                                <div className={"statusCell pointing onLeft veryBig"} >
                                                    <OrderStatuselect orderStatus={theOrder.ord?.orderStatus}
                                                        setOrderStatus={(statBack) => setNewStatus(theOrder.ord?._id, statBack)}
                                                    />
                                                </div>
                                                <div className="bold onLeft line2Rem">
                                                    סטטוס הזמנה:
                                                </div>
                                                {(theOrder.ord?.OrderType !== "Take" && theOrder.ord?.sendiStatus && theOrder.ord?.sendiStatus !== "") ?
                                                    <>
                                                        <div className={"fullRow leftText spaceTopBig"}>
                                                            {/* <span className={"orderItmTxt "}>שליח:</span>&nbsp; */}
                                                            <Button className={"btnDeliveryDetail"} variant="contained"
                                                                onClick={() => PopDeliveryDetails(theOrder.ord.orderNUM,
                                                                    theOrder.rst?.buissName,
                                                                    (theOrder.ord.deliveryAddress.street + " " +
                                                                        theOrder.ord.deliveryAddress.num + " " +
                                                                        theOrder.ord.deliveryAddress.city))}>
                                                                {theOrder.ord.sendiStatus}
                                                            </Button>
                                                            {/* <span className={"orderItmTxt sendiStatus big"}>{theOrder.ord.sendiStatus}</span> */}
                                                        </div>
                                                    </>
                                                    : ""}
                                                {theOrder.ord?.orderStatus === "מבוטל" && theOrder.ord?.cancelReason ?
                                                    <div className={"fullRow leftText spaceTopBig"}>
                                                        <span className={"bold veryBig"}>
                                                            סיבת הביטול: {theOrder.ord?.cancelReason}
                                                        </span>
                                                    </div>
                                                    : ""}

                                            </div>
                                            <br />
                                            <div className="col-md-6 text-md-right">
                                                <div className="invoice-date">
                                                    {
                                                        (times.theEnd) ?
                                                            (theOrder.ord?.orderStatus !== "סגור") && <div>
                                                                <div className={"myButton"} style={{ backgroundColor: times.theCol, color: times.theForCol, fontWeight: "bold" }} id={theOrder.ord?._id + "_Count"}>
                                                                    <div className={"fullRow"}>הסתיים הזמן לפני</div>
                                                                    <div>
                                                                        {times?.theTm}<br />
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <div>
                                                                <div className={"myButton"} style={{ backgroundColor: times.theCol, color: times.theForCol, fontWeight: "bold" }} id={theOrder.ord?._id + "_Count"}>
                                                                    <div>זמן לביצוע</div>
                                                                    <div>
                                                                        {times?.theTm}<br />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-12 overflowHid spaceMargeTopbig textLeft"} title={"הוסף מוצר"}>
                                        <Button className={"btnMediumMnu"} variant="contained" onClick={() => addOrderItem()}>+</Button>
                                    </div>
                                    {screenWidth > 800 ?
                                        <InvoiceTable theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} /> :
                                        <InvoiceMobiTable theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} />}
                                </div>
                            </div>
                        </div>

                        {
                            (arabic === "עברית") ? "" : <div className="ms-panel-header approveLine no_print">
                                <button type="button" className="onLeft btn btn-primary spaceMargeTopbig" onClick={() => updateTheOrder()}>שמור</button>
                            </div>
                        }

                    </div>
                    {thePopUp}
                </div>

                <div id={"printingCloser"} className="onlyOnPrint printingCloser">
                    <InvoicePrint theOrder={theOrder} arabic={(arabic === "עברית") ? true : false} />
                </div>

                <iframe id={"ifmcontentstoprint"}></iframe>
            </>

    );

}


export default Content;
