import { AddBoxOutlined } from '@mui/icons-material';
import { Button, Checkbox, MenuItem, Select } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import React, { Component, useContext, useEffect, useState } from 'react';
import OrderFunctions from '../../../../MyFunctions/OrderFunctions';
import MySpinner_Wait from '../../../MySections/DTechComps/MySpinner_Wait';
import MyDateTime from '../../../MySections/TimeSections/MyDateTime';
import MultiLIstEditSelect from './MultiLIstEditSelect';
import OrderStatuselect from './OrderStatuselect';
import MultiUpdateCheck from '../../../MySections/PopUps/MultiUpdateCheck';
import { UserContext } from '../../../MyContexts/Users_Context';
import PopUpTheComp from '../../../MySections/DTechComps/PopUpTheComp';
import DeliveryDetails from '../DeliveryDetails/DeliveryDetails';
import utilsDeliveryDetails from '../../../../MyConns/_Utils/utilsDeliveryDetails';
import myFunctions from '../../../../myFunctions';

const ListOrdersTable = (props) => {

    const [user, setUser] = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [multiSelect, setMultiSelect] = useState(props.theList.map((itm, indx) => { return { indx, checked: false, orderId: itm.ord._id } }));
    const [multiSelectStatus, setMultiSelectStatus] = useState("-1");
    const [selectAll, setSelectAll] = useState(false);
    const [thePopUp, setThePopUp] = useState("");


    useEffect(() => {
        setMultiSelect(props.theList.map((itm, indx) => { return { indx, checked: false, orderId: itm.ord._id } }))
    }, [props.theList])


    const orderBythis = (byWhat, ordUserOrBuiss) => {
        props.orderBythis(byWhat, ordUserOrBuiss);
    }
    const rowCellClick = (ordId, item) => {
        props.rowCellClick(ordId);
    }

    const setNewStatus = (orderId, statBack) => {
        props.setNewStatus(orderId, statBack);
    }

    const updateMulti = (i) => {
        let tempo = [...multiSelect];
        tempo[i].checked = !tempo[i].checked
        setMultiSelect(tempo)
        setSelectAll(false)
    }

    const updateSelAll = () => {
        let tempo = multiSelect.map((itm, indx) => { return { indx, checked: !selectAll, orderId: itm.orderId } });
        setMultiSelect(tempo)
        setSelectAll(!selectAll)
    }


    const checkApproveUpdate = () => {
        setThePopUp(<MultiUpdateCheck title={`האם בטוח לשנות את הנבחרים ל${multiSelectStatus}?`} sucecssFunc={() => updateTheSelected()} />)

    }


    const PopDeliveryDetails = (orderNUM, buisName, AddrsTo) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"OrderDispPop"}
                popDiagStyle={"OrderDispValPop"}
                errorMsg={""}
                okClick={() => { setThePopUp() }}
                btnClick1={true}
                Title={["פרטי השליחות"]}
                theInsideComp={
                    <DeliveryDetails cssStyl={"OrderDisplayPop"}
                        cancelClick={() => setThePopUp()}
                        okClick={() => { setThePopUp() }}
                        Title={[""]}
                        ordNum={orderNUM}
                        buisName={buisName}
                        AddrsTo={AddrsTo}
                    />}
            />

        )
    }

    const updateTheSelected = () => {

        setLoading(true)
        if (multiSelectStatus != "-1") {

            let theSelects = multiSelect.filter((it) => it.checked)

            if (theSelects != null) {
                props.setNewStatusToList(theSelects, multiSelectStatus)

            }
        }

        setLoading(false)

    }


    return (
        <div className="row lstOrderTbls">
            <div className="col-xl-12">
                <div className="table-responsive ms-panel-body">
                    <div className={"fullRow spacePad"}>
                        <MultiLIstEditSelect multiSelVal={multiSelectStatus}
                            changeToStatus={(statBack) => setMultiSelectStatus(statBack)}
                        />
                        <Button variant="contained" className={"theSBtn"} onClick={() => checkApproveUpdate()}
                            startIcon={<ModeEditIcon style={{ color: 'white' }} />}>
                            בצע
                        </Button>
                    </div>
                    {loading ?
                        <MySpinner_Wait />
                        :
                        <table className="table table-hover thead-primary">
                            <thead>
                                <tr>
                                    <th scope="col" className={"pointing"} onClick={() => { }}>
                                        <Checkbox checked={selectAll} onChange={() => { updateSelAll() }} className="chkBx" />
                                    </th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("orderNUM", "ord")}>מס' הזמנה</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("buissName", "buiss")}>שם העסק</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("payt", "ord")}>תשלום</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("firstName", "theUser")}>שם הלקוח</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("deliveryAddress.street", "ord")}>יעד</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("orderStatus", "ord")}>סטטוס</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("sendiStatus", "ord")}>שליח</th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("sendiStatus", "ord")}></th>
                                    <th scope="col" className={"pointing"} onClick={() => orderBythis("dateCreated", "ord")}>מועד ההזמנה</th>
                                    <th scope="col">תזמון</th>
                                    <th scope="col">מחיר</th>
                                </tr>
                            </thead>
                            <tbody>

                                {/* רשימה ראשית */}
                                {
                                    props.theList.map((item, i) => {
                                        let showme = (item.ord.paymentStatus !== "ממתין לחיוב") ?
                                            true :
                                            (user.permiss === "admin") ? true : false;

                                        return (
                                            <tr key={item.ord._id}
                                                className={`${showme ? "" : "hideMe"} pointing ${item.ord.paymentStatus === "ממתין לחיוב" ? "tempoOrdRow" : ""}`}
                                                itmid={item.ord._id} id={item.ord.orderNUM}>
                                                <td>
                                                    <Checkbox checked={multiSelect[i]?.checked || false} onChange={() => { updateMulti(i) }} className="chkBx" />
                                                </td>
                                                <td className={"lstCell"} onClick={() => rowCellClick(item.ord._id, item)}>{item.ord.orderNUM}</td>
                                                <td className={"lstCell"} onClick={() => rowCellClick(item.ord._id, item)}>{item.buiss ? item.buiss.buissName : "לא ידוע"}</td>
                                                <td className={"lstCell"} onClick={() => rowCellClick(item.ord._id, item)}>{OrderFunctions.payingTYpe(item)}</td>
                                                <td className={"lstCell"} onClick={() => rowCellClick(item.ord._id, item)}> {item.theUser.firstName}  {item.theUser.lastName}</td>

                                                <td onClick={() => rowCellClick(item.ord._id, item)}>
                                                    {(item.ord.OrderType === "Deliver") ?
                                                        <div title={`${item.ord.deliveryAddress.street} ${item.ord.deliveryAddress.num} ${item.ord.deliveryAddress.city}`}>
                                                            {item.ord.deliveryAddress.street} {item.ord.deliveryAddress.num} {item.ord.deliveryAddress.city}
                                                        </div>
                                                        :
                                                        "TAKE"}
                                                </td>
                                                <td className={"statusCell pointing"} >
                                                    <OrderStatuselect orderStatus={item.ord.orderStatus}
                                                        setOrderStatus={(statBack) => setNewStatus(item.ord._id, statBack)}
                                                    />
                                                </td>
                                                {(item.ord.OrderType === "Deliver") ?
                                                    <>
                                                        {(item.buiss?.sendiRestName && item.buiss?.sendiRestName != "") ?
                                                            <td className={"sendiStatus lstCell pointing"}
                                                                onClick={() => PopDeliveryDetails(item.ord.orderNUM, item.buiss.buissName,
                                                                    item.ord.deliveryAddress.street + " " + item.ord.deliveryAddress.num + " " + item.ord.deliveryAddress.city)}>

                                                                <div className={"dvStat"} title={item.ord.sendiStatus}>{item.ord.sendiStatus?.split('-')[0]?.trim()}</div>
                                                            </td>
                                                            :
                                                            <td className={"sendiStatus lstCell pointing"}
                                                                onClick={() => { }}>
                                                                <div className={"dvStat"} title={item.ord.sendiStatus}>שליח פרטי</div>
                                                            </td>
                                                        }
                                                    </>
                                                    :
                                                    <td className={"sendiStatus lstCell pointing"}>
                                                        <div className={"dvStat"} ></div>
                                                    </td>
                                                }
                                                <td id={i + "_SendiCome"} onClick={() => rowCellClick(item.ord._id, item)}>
                                                    <div id={item.ord._id + "_SendiTm"}>
                                                        <br />
                                                    </div>
                                                </td>
                                                <td onClick={() => rowCellClick(item.ord._id, item)}>{MyDateTime.getDateAndTime(item.ord.dateCreated)}</td>

                                                <td className={"timerTd lstCell"} onClick={() => rowCellClick(item.ord._id, item)} id={i + "_Count"}>
                                                    <div id={item.ord._id + "_Cnt"}>
                                                        <br />
                                                    </div>
                                                </td>
                                                {/* <td>{item.deliverytime}</td> */}
                                                < td onClick={() => rowCellClick(item.ord._id, item)}> {OrderFunctions.getOrderTotalPrice(item.ord)} ₪</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    }
                </div>
            </div >
            {thePopUp}
        </div >
    )
}

export default ListOrdersTable;